.hero {
  background-image: url("../../../../public/images/Banner.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 70vh;
  width: 100%;
  opacity: 0.8;
}
.hero .container {
  padding-top: 5%;
  text-align: center;
}
.hero h1 {
  color: #fff;
  font-size: 60px;
}
.hero p {
  color: #fff;
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 5%;
  }
}
